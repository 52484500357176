import React from 'react';
import Img from 'gatsby-image';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import ScrollTextWithImage from '@components/ScrollTextWithImage';
import Button from '@components/Button';
import { Link } from 'gatsby';

const Branding = ({
    brandingPhoneLeft,
    brandingPhoneRight,
    siteImage,
    brandingBottomImage,
    textCards,
    brandingWeddingText,
}) => {
    return (
        <section>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.brandingHeadingWrapper}>
                            <div className={styles.brandingHeading}>
                                <h2>
                                    Our detailed planning resulted in a polished website, mobile
                                    app, admin panel and API for both guests and hosts.
                                </h2>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <div className={styles.brandingSolutionContainer}>
                <Row>
                    <Col xs={12} md={12} lg={6} xl={4}>
                        <div className={styles.solutionImageLeft}>
                            <Img fluid={brandingPhoneLeft} imgStyle={{ objectFit: 'cover' }} />
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={8} xl={4}>
                        <div className={styles.solutionText}>
                        <h2>Our Solution</h2>
                        <p>
                            Over several visits to Retreat East we worked with their team to plan
                            and design a new website prototype that focuses on high quality visuals
                            and an eye catching hero video on the homepage. Our goal was to really
                            bring across to the website visitors that this website and business
                            offers “Idyllic escapes in the heart of suffolk”. Each barn had complete
                            new photography, the restaurant menus were converted from PDFs to a
                            fully digital format to improve SEO. The spa, events, packages and
                            explore pages were also completely redesigned from scratch to offer a
                            much more story-like experience rather than the previous heavy text
                            based experience.
                        </p>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={4}>
                        <div className={styles.solutionImageRight}>
                            <Img fluid={brandingPhoneRight} imgStyle={{ objectFit: 'cover' }} />
                        </div>
                    </Col>
                </Row>
            </div>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <ScrollTextWithImage imageFluid={siteImage} imageLeft>
                    <Divider xsMargin={5} mdMargin={0} />
                    <p>
                        The website branding focuses on a refreshing white and light grey palette
                        with a deep teal primary colour which portrays trust, nature, relaxation and
                        health. This new brand overhaul supplements the rich product photography
                        which exhibits well lit, inviting and relaxing scenes across the website.
                        <br />
                        <br />
                        Want to know more about our web-design services?
                    </p>
                    <Link to="/service/web-platforms/web-design">
                        <Button text="Read more" />
                    </Link>
                </ScrollTextWithImage>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Img fluid={textCards} imgStyle={{ objectFit: 'contain' }} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.brandingGraphic}>
                            <img src={brandingWeddingText} alt="Wedding" />
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Img fluid={brandingBottomImage} imgStyle={{ objectFit: 'cover' }} />
        </section>
    );
};

export default Branding;
