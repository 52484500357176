import React from 'react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import { graphql, Link } from 'gatsby';
import Layout from '@components/Layout';
import SEO from '@components/Seo';

import styles from './styles.module.scss';
import Hero from '@views/case-studies/retreat-east/Hero';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import Description from '@views/case-studies/Description';
import Branding from '@views/case-studies/retreat-east/Branding';
import ReadMore from '@views/case-studies/ReadMore';
import WebDevelopment from '@views/case-studies/retreat-east/WebDevelopment';

const RetreatEast = ({ data }) => {
    return (
        <Layout>
            <SEO
                title="Retreat East • Case Studies"
                image={data?.gundiesPreview?.publicURL}
                description=" We not only helped Liveyou by redesigning their website but we equipped them with all the social marketing devices they need to drive accurately targeted market traffic to their website."
            />
            <div className={styles.caseStudyWrapper}>
                <Hero image={data?.hero?.childImageSharp?.fluid} />

                <ProjectOverview
                    client="Retreat East"
                    name="Luxury countryside retreats"
                    backgroundColor="#3D5E67"
                    description={
                        <p>
                            We were tasked to build a new luxury website which complements Retreat
                            East’s new branding and strategy and to increase conversion rates of
                            bookings for each barn.
                        </p>
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Description
                    image={data?.description?.childImageSharp?.fluid}
                    textLeft={
                        'Retreat East is a rejuvenating retreat in the beautiful Suffolk countryside. Escape to the heart of the Suffolk countryside where they have reimagined the Good Life at their sustainable retreat regenerated from a former dairy farm.'
                    }
                    textRight={
                        <div>
                            <p>
                                We worked with Retreat East to propel their new business image
                                forward across the digital space. The emphasis was on showcase their
                                barns rooms, private events hire, restaurant and spa in one, high
                                quality, premium-feel website.
                            </p>
                            <p>
                                We also facilitated the launch of their new online store which sells
                                a range of locally produced luxury products to help secure another
                                revenue stream. This was alongside the more notability larger
                                project of introducing Retreat East Weddings, a complete sub-website
                                which showcases a range of wedding packages.
                            </p>
                        </div>
                    }
                />

                <Branding
                    brandingPhoneLeft={data?.brandingPhoneLeft?.childImageSharp?.fluid}
                    brandingPhoneRight={data?.brandingPhoneRight?.childImageSharp?.fluid}
                    siteImage={data?.brandingSiteImage?.childImageSharp?.fluid}
                    textCards={data?.brandingTextCards?.childImageSharp?.fluid}
                    brandingWeddingText={data?.brandingWeddingText?.publicURL}
                    brandingBottomImage={data?.brandingBottomImage?.childImageSharp?.fluid}
                />

                <WebDevelopment
                    webWeddingsUx={data?.webWeddingsUx?.publicURL}
                    scrollImage={data?.webScrollImage?.childImageSharp?.fluid}
                />
                <ReadMore />
            </div>
        </Layout>
    );
};

export default RetreatEast;

export const query = graphql`
    query {
        gundiesPreview: file(absolutePath: { regex: "/case-studies-the-gundies-preview.png/" }) {
            publicURL
        }
        hero: file(absolutePath: { regex: "/case-studies-retreat-east-hero.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        description: file(absolutePath: { regex: "/case-studies-re-description.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSiteImage: file(absolutePath: { regex: "/case-studies-re-site-long.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingPhoneLeft: file(absolutePath: { regex: "/case-studies-re-phone-left.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingPhoneRight: file(absolutePath: { regex: "/case-studies-re-phone-right.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingTextCards: file(absolutePath: { regex: "/case-studies-re-cards-with-text.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingWeddingText: file(absolutePath: { regex: "/case-studies-re-wedding-text.svg/" }) {
            publicURL
        }
        brandingBottomImage: file(absolutePath: { regex: "/case-studies-re-bottom-image.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webScrollImage: file(absolutePath: { regex: "/case-studies-re-web-scroll-image.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        webWeddingsUx: file(absolutePath: { regex: "/case-studies-re-weddings-ux.svg/" }) {
            publicURL
        }
        siteOverviewEsIconAdmin: file(absolutePath: { regex: "/es-icon-admin.svg/" }) {
            publicURL
        }
    }
`;
