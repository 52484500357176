import React from 'react';
import Img from 'gatsby-image';

import CustomSwiper from '@components/CustomSwiper';
import { SwiperSlide } from 'swiper/react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import styles from './styles.module.scss';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import ScrollTextWithImage from '@components/ScrollTextWithImage';
import { Link } from 'gatsby';
import Button from '@components/Button';

const WebDevelopment = ({ webWeddingsUx, scrollImage }) => {
    return (
        <section>
            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <div className={styles.webUxGraphWrapper}>
                <h2>
                    The UX was built to channel a user through each step and present them to call to
                    action at the end. This was to ensure a large number of Retreat East's customers
                    were as informed as possible before they made first contact.
                </h2>
                <img src={webWeddingsUx} alt="Wedding UX graph" />
            </div>

            <Divider margin={5} />
            <Divider margin={5} />

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more about our <br /> UX Services?
                    </h2>
                }
                link="/service/web-platforms/ux"
                buttonText="Read more"
            />


            <Divider margin={5} />
            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <ScrollTextWithImage imageFluid={scrollImage}>
                    <Divider xsMargin={5} mdMargin={0} />
                    <h2>Store</h2>
                    <p>
                        After successfully launching an MVP, we took the time to split up the
                        project into 3 dedicated code bases. After building the multi-purpose API, a
                        great deal of time was spent focusing on UX and SEO to keep users engaged on
                        the platform. Building the main site in React meant we also could quickly
                        release mobile apps in React Native.
                        <br />
                        <br />
                        If you’re interested in eCommerce or opening a store on your website, please
                        follow the link below.
                    </p>
                    <Link to="/service/web-platforms/e-commerce">
                        <Button text="Read more" />
                    </Link>
                </ScrollTextWithImage>
            </Grid>
        </section>
    );
};
export default WebDevelopment;
